<template>
  <div>
    <div
      class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition vue-data-table mb-0"
      style="width: 100%;"
    >
      <table class="el-table__body" style="width: 650px;">
        <!-- <tr v-if="esignData.user">
                  <td>Employee</td>
                  <td>:: {{esignData.employee.first_name}} {{esignData.employee.last_name}}</td>
        </tr>-->
        <tr v-if="esignData.email" class="el-table__row">
          <td>
            <span class="cell font-weight-bold">Email</span>
          </td>
          <td>
            <span class="cell blue-dark">{{esignData.email}}</span>
          </td>
        </tr>
        <tr class="el-table__row">
          <td>
            <span class="cell font-weight-bold">OTP Sent On</span>
          </td>
          <td>
            <span
              class="cell blue-dark"
            >{{esignData.verification_sent_at | moment("MM-DD-YYYY HH:mm:ss")}}</span>
          </td>
        </tr>
        <tr class="el-table__row">
          <td>
            <span class="cell font-weight-bold">Signed On</span>
          </td>
          <td>
            <span class="cell blue-dark">{{esignData.signed_at | moment("MM-DD-YYYY HH:mm:ss")}}</span>
          </td>
        </tr>
        <tr class="el-table__row">
          <td>
            <span class="cell font-weight-bold">IP Address</span>
          </td>
          <td>
            <span class="cell blue-dark">{{esignData.ip}}</span>
          </td>
        </tr>
        <tr v-if="esignData.location" class="el-table__row">
          <td>
            <span class="cell font-weight-bold">Signed from Location</span>
          </td>
          <td>
            <span
              class="cell blue-dark"
            >{{esignData.location.district}}, {{esignData.location.city}}, {{esignData.location.state_prov}}, {{esignData.location.zipcode}},{{esignData.location.country_name}}</span>
          </td>
        </tr>

        <tr class="el-table__row">
          <td>
            <span class="cell font-weight-bold">Signature</span>
          </td>
          <td>
            <span class="cell blue-dark">
              <img :src="esignData.signature" height="100" />
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name:"signature-ESignatureDataView",
  props: ["esignData"]
};
</script>

<style>
</style>