var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition vue-data-table mb-0",staticStyle:{"width":"100%"}},[_c('table',{staticClass:"el-table__body",staticStyle:{"width":"650px"}},[(_vm.esignData.email)?_c('tr',{staticClass:"el-table__row"},[_vm._m(0),_c('td',[_c('span',{staticClass:"cell blue-dark"},[_vm._v(_vm._s(_vm.esignData.email))])])]):_vm._e(),_c('tr',{staticClass:"el-table__row"},[_vm._m(1),_c('td',[_c('span',{staticClass:"cell blue-dark"},[_vm._v(_vm._s(_vm._f("moment")(_vm.esignData.verification_sent_at,"MM-DD-YYYY HH:mm:ss")))])])]),_c('tr',{staticClass:"el-table__row"},[_vm._m(2),_c('td',[_c('span',{staticClass:"cell blue-dark"},[_vm._v(_vm._s(_vm._f("moment")(_vm.esignData.signed_at,"MM-DD-YYYY HH:mm:ss")))])])]),_c('tr',{staticClass:"el-table__row"},[_vm._m(3),_c('td',[_c('span',{staticClass:"cell blue-dark"},[_vm._v(_vm._s(_vm.esignData.ip))])])]),(_vm.esignData.location)?_c('tr',{staticClass:"el-table__row"},[_vm._m(4),_c('td',[_c('span',{staticClass:"cell blue-dark"},[_vm._v(_vm._s(_vm.esignData.location.district)+", "+_vm._s(_vm.esignData.location.city)+", "+_vm._s(_vm.esignData.location.state_prov)+", "+_vm._s(_vm.esignData.location.zipcode)+","+_vm._s(_vm.esignData.location.country_name))])])]):_vm._e(),_c('tr',{staticClass:"el-table__row"},[_vm._m(5),_c('td',[_c('span',{staticClass:"cell blue-dark"},[_c('img',{attrs:{"src":_vm.esignData.signature,"height":"100"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"cell font-weight-bold"},[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"cell font-weight-bold"},[_vm._v("OTP Sent On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"cell font-weight-bold"},[_vm._v("Signed On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"cell font-weight-bold"},[_vm._v("IP Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"cell font-weight-bold"},[_vm._v("Signed from Location")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"cell font-weight-bold"},[_vm._v("Signature")])])
}]

export { render, staticRenderFns }